// extracted by mini-css-extract-plugin
export var teamSection = "Team-module--teamSection--PF1NL";
export var teamHeading = "Team-module--teamHeading--isGVo";
export var alumniCohort = "Team-module--alumniCohort--+6vgN";
export var orangeText = "Team-module--orangeText--i0skq";
export var greyText = "Team-module--greyText---P6w0";
export var conqCEOs = "Team-module--conqCEOs--z1ghy";
export var teamFilter = "Team-module--teamFilter--5RLxX";
export var fButtonActive = "Team-module--fButtonActive--NGg6K";
export var fButtonInactive = "Team-module--fButtonInactive--cyVIU";
export var fButton = "Team-module--fButton--USwNF";
export var searchBarContainer = "Team-module--searchBarContainer--Z5pj4";
export var searchBar = "Team-module--searchBar--RvtZO";
export var spacer = "Team-module--spacer--gYLDq";
export var mentorGrid = "Team-module--mentorGrid--rW8z1";