import * as React from "react";
import MentorCard from "../components/MentorCard/MentorCard";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../sections/Navbar/Navbar";
import NetworkHero from "../sections/NetworkHero/NetworkHero";
import RegBanner from "../sections/RegBanner/RegBanner";

import "../fonts/stylesheet.css";

import {
  teamSection,
  teamHeading,
  greyText,
  conqCEOs,
  teamFilter,
  fButtonActive,
  fButtonInactive,
  fButton,
  searchBarContainer,
  searchBar,
  mentorGrid,
  spacer,
} from "../sections/Team/Team.module.css";

const text = {
  fontFamily: "Manrope",
};

const MentorsPage = () => {
  const [filterState, setFilterState] = React.useState("Academy");

  const [angels, setAngels] = React.useState();
  const [ref, setRef] = React.useState();

  const getRef = () => {
    fetch("https://conquest.prismic.io/api/v2/", {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            getData(res.data.refs[0].ref);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };
  const getData = (key) => {
    fetch(
      "https://conquest.cdn.prismic.io/api/v2/documents/search?ref=" +
        key +
        
        "&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ",
      {
        method: "GET",
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data, 
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            setAngels(res.data.results);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  React.useEffect(() => {
    getRef();
  }, []);

  return (
    <main style={text}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Conquest: India's First and Largest Student-run Startup Accelerator</title>
      </Helmet>
      <Navbar />
      <NetworkHero
        img1="https://user-images.githubusercontent.com/69129797/155963444-42490318-c7d3-4722-83ca-7df55471be00.jpeg"
        img4="https://user-images.githubusercontent.com/69129797/155963450-54d8e5d8-bec5-4c84-8eb6-4ca1d67eb9c5.jpeg"
        img3="https://user-images.githubusercontent.com/69129797/155964112-d5573d9f-8816-4ae3-9bbc-001247e9ef4f.jpeg"
        img2="https://user-images.githubusercontent.com/69129797/155963460-2ab76a2d-c99d-4f16-a904-9c8dac24a54d.jpeg"
        img6="https://user-images.githubusercontent.com/69129797/155964117-42f3ebf3-490f-455c-8d37-a6c9edc8099c.jpeg"
        img5="https://user-images.githubusercontent.com/69129797/155964104-275d9e1b-2285-42fe-8d07-4f7032545e44.jpeg"
        meet="meet our"
        context="mentors"
        desc="Over the course of 18 years, Conquest has been associated with some amazing mentors from different walks of the startup ecosystem. Have a look!"
      />
      <div class={spacer} />
      {/* <div class={searchBarContainer}>
                <input type="text" class={searchBar} placeholder="Search for mentors by name, interests, or company" />
            </div>

            <div class={teamFilter}>  
                <button 
                    class={filterState === "All" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("All")}
                >All</button>
                <button 
                    class={filterState === "Academy" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Academy")}
                >Electric Vehicles</button>
                <button 
                    class={filterState === "Design" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Design")}
                >Crypto</button>
                <button 
                    class={filterState === "Finance" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Finance")}
                >FinTech</button>
                <button 
                    class={filterState === "Media" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Media")}
                >SaaS</button>
                <button 
                    class={filterState === "Mentor" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Mentor")}
                >Cloud Computing</button>
                <button 
                    class={filterState === "Outreach" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Outreach")}
                >Growth</button>
                <button 
                    class={filterState === "Tech" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Tech")}
                >Hardware</button>
            </div> */}

      {angels ? (
        <>
          {angels.map((result, id) => (
            <>
              <div class={mentorGrid}>
                {result.type === "horizontal_profile" &&
                result.data.type[0].text === "mentors" ? (
                  <>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((ang, id) => (
                          <MentorCard
                            name={ang.primary.name[0].text}
                            imgSrc={ang.primary.image.url}
                            designation={ang.primary.designation[0].text}
                            tag1={ang.items[0].tag}
                            tag2={ang.items[1].tag}
                          />
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </>
          ))}
        </>
      ) : null}

      <RegBanner />
      <Footer />
    </main>
  );
};

export default MentorsPage;
