import * as React from 'react'
import {
    NetworkHeroContainer,
    NetworkLeftImg,
    imgL1,
    imgL2,
    imgL3,
    NetworkText,
    NetworkH,
    greyText,
    NetworkDesc,
    NetworkRightImg,
    imgR1,
    imgR2,
    imgR3,
} from './NetworkHero.module.css'


const NetworkHero = (props) => {
    return (
        <div class={NetworkHeroContainer}>
            <div class={NetworkLeftImg}>
                <img src={props.img1} class={imgL1} />
                <img src={props.img2} class={imgL2} />
                <img src={props.img3} class={imgL3} />
            </div>

            <div class={NetworkText}>
                <div class={NetworkH}>
                    <span class={greyText}>{props.meet}</span> <br></br>{props.context}
                </div>
                <div class={NetworkDesc}>{props.desc}</div>
            </div>

            <div class={NetworkRightImg}>
                <img src={props.img4} class={imgR1} />
                <img src={props.img5} class={imgR2} />
                <img src={props.img6} class={imgR3} />
            </div>
        </div>
    )
}

export default NetworkHero;