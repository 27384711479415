// extracted by mini-css-extract-plugin
export var NetworkHeroContainer = "NetworkHero-module--NetworkHeroContainer--BQ-jj";
export var NetworkLeftImg = "NetworkHero-module--NetworkLeftImg--YRUs6";
export var NetworkRightImg = "NetworkHero-module--NetworkRightImg--2ErZH";
export var NetworkText = "NetworkHero-module--NetworkText--400WE";
export var imgL1 = "NetworkHero-module--imgL1--+p5JV";
export var imgL2 = "NetworkHero-module--imgL2--LR8QE";
export var imgL3 = "NetworkHero-module--imgL3--5lyvf";
export var imgR1 = "NetworkHero-module--imgR1---Uif0";
export var imgR2 = "NetworkHero-module--imgR2--JvUFC";
export var imgR3 = "NetworkHero-module--imgR3--EiXNu";
export var NetworkH = "NetworkHero-module--NetworkH--Kp0P0";
export var greyText = "NetworkHero-module--greyText--7ZA6E";
export var NetworkDesc = "NetworkHero-module--NetworkDesc--dqNza";